import { useMeService } from '@app/api/me';
import EllipsedText from '@app/components/shared/EllipsedText';
import i18n from '@app/config/i18n.config';
import { useConfirmRedirectContext } from '@app/store/confirmRedirectContext/confirmRedirectContext';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext';
import { ROUTES_PATH } from '@app/utils';
import {
  AppBar,
  Box,
  createStyles,
  Divider,
  Grid,
  Icon,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Popover,
  TextField,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  AccountCircleOutlined as UserIcon,
  ArrowDropDown,
  ArrowDropUp,
  ArrowRight as ArrowRightIcon,
  Language as LanguageIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Search as SearchIcon
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthenticationContext } from '../store/authentication/authenticationContext';
import { useLanguageContext } from '../store/languageContext/languageContext';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH, UserRoles } from '../utils/constants';
import FormButton from './shared/FormButton';
import { getStatusTranslationKey } from './shared/StatusComponent';
import { PlantStatus } from '@app/api/plants/PlantsGetResponse';

interface HeaderProps {
  open: boolean;
}

const Header = ({ open }: HeaderProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        display: 'flex',
        flexDirection: 'column',
        padding: `0 5px`,
      },
      collapsed: {
        transform: `translatX(${COLLAPSED_DRAWER_WIDTH}px)`,
        width: `calc(100% - ${COLLAPSED_DRAWER_WIDTH}px)`,
      },
      appBarShift: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      toolbar: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '5px 0px 5px 0px',
        minHeight: 'initial',
      },
      title: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      menuButton: {
        marginRight: 36,
      },
      hide: {
        display: 'none',
      },
      plantPopover: {
        marginTop: 10,
        minWidth: 300,
      },
      userPopover: {
        marginTop: 10,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
      languagePopover: {
        marginTop: 16,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
      plantPopoverContainer: {
        backgroundColor: theme.palette.primary.main,
        padding: 12,
      },
      plantPopoverButton: {
        backgroundColor: theme.palette.common.white,
      },
      searchContainer: {
        backgroundColor: 'white',
        marginBottom: 16,
      },
      plantsContainer: {
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        maxHeight: 400,
        maxWidth: 300,
        overflowY: 'auto',
      },
      plantsItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '12px 4px',
        width: '100%',
        maxWidth: 300,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      divider: {
        margin: '0 5px',
        backgroundColor: theme.palette.secondary.main,
      },
      headerSeparator: {
        height: '20px',
        width: '2px' ,
        content: '',
        backgroundColor: theme.palette.custom.grey2.main,
      },
      headerTexts: {
        color: theme.palette.custom.grey2.main,
      },
      headerBorderBottom: {
        backgroundColor: theme.palette.custom.grey2.main,
        height: '2px',
        borderRadius: '2px',
        width: '100%',
        content: ''
      },
      blockedPlant: {
        color: theme.palette.custom.userStatus.blocked.main,
        fontWeight: 'bold'
      },
    }),
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { changePlant } = useMeService();
  const {state: { selectedLanguage: language }, updateLanguage} = useLanguageContext();
  const { state: { userConfirmedRedirect, shouldDisplayPrompt }, updateConfirmRedirectState } = useConfirmRedirectContext();

  const { pathname } = useLocation();
  const [clickedToChangePlantOnNewHeatScreens, setClickedToChangePlantOnNewHeatScreens] = useState(false);
  const [clickedPlantId, setClickedPlantId] = useState("");

  const {
    state: { accountInfo, userInfoObject },
    logout,
    loadUserInfo
  } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const [userMenu, setUserMenu] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [plantMenu, setPlantMenu] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [languageMenu, setLanguageMenu] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [search, setSearch] = useState('');

  const { loadPlantValidations } = usePlantContext();

  const userMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenu(event.currentTarget);
  };

  const plantMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPlantMenu(event.currentTarget);
  };

  const languageMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const plantMenuClose = () => {
    setPlantMenu(null);
  };

  const languageMenuClose = () => {
    setLanguageMenu(null);
  };

  const filteredPlants = useMemo(
    () =>
      userInfo?.plants
        ?.filter(
          plant =>
            plant.name.toLowerCase().includes(search.toLowerCase()) ||
            plant.customerName?.toLowerCase().includes(search.toLowerCase()),
        )
        ?.sort((a, b) => a.name.localeCompare(b.name)),
    [search, userInfo],
  );

  const changeUserPlant = useCallback(async (plantId: string) => {
    await changePlant({ plantId });
    await loadUserInfo();
    await loadPlantValidations(plantId);
  }, [changePlant, loadPlantValidations, loadUserInfo]);

  useEffect(() => {
    if (
      pathname === ROUTES_PATH.PLANT_OVERVIEW
      && clickedToChangePlantOnNewHeatScreens
      && userConfirmedRedirect
    ) {
      setClickedToChangePlantOnNewHeatScreens(false);
      changeUserPlant(clickedPlantId);
    }
  },[
    pathname,
    clickedToChangePlantOnNewHeatScreens,
    changeUserPlant,
    clickedPlantId,
    userConfirmedRedirect
  ])

  const onClickToChangeUserPlant = async (plantId: string) => {
    history.push(ROUTES_PATH.PLANT_OVERVIEW);
    plantMenuClose();

    const isOnCreateOrEditHeatScreens =
      pathname.includes("heat-calculation") && !pathname.includes("view");

    const isOnEquipmentScreens =
      pathname.includes(ROUTES_PATH.PLANT_EQUIPMENT);

    if (isOnCreateOrEditHeatScreens || isOnEquipmentScreens) {
      updateConfirmRedirectState(false);
      setClickedToChangePlantOnNewHeatScreens(true);
      setClickedPlantId(plantId);

      if (!shouldDisplayPrompt) {
        updateConfirmRedirectState(true)
      };
    } else {
      changeUserPlant(plantId);
    }
  };

  const getLanguagePopoverPosition = () => {
    const anchorElement = document?.getElementById('menu-item-anchor');
    let anchorPosition = { top: 200, left: 400 };

    if (anchorElement !== null) {
      anchorPosition = anchorElement.getBoundingClientRect();
    }

    return { top: anchorPosition?.top - 17, left: anchorPosition?.left };
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const updateUserLanguage = async (lang: "en" | "pt-BR" | "es" | undefined) => {
    await updateLanguage(lang);
    await loadUserInfo();
    languageMenuClose();
  };

  const renderSelectedPlant = (): JSX.Element => {
    const showPlantSelector = userInfo?.userProfileName === UserRoles.ADMINISTRATOR
      || userInfo?.userProfileName === UserRoles.KEY_USER
      || userInfo?.userProfileName === UserRoles.TECHNICAL_CONSULTANT;
    const plantIsBlocked = userInfo?.selectedPlant.plantStatus === PlantStatus.BLOCKED;
    const plantNameClass = `${classes.headerTexts} ${plantIsBlocked && classes.blockedPlant}`;
    let plantName = userInfo?.selectedPlant.name;

    if (userInfo?.selectedPlant && userInfo?.selectedPlant.plantStatus !== PlantStatus.ACTIVE) {
      plantName = `${plantName} (${t(getStatusTranslationKey(userInfo?.selectedPlant.plantStatus))})`
    }

    return (
      <FormButton
        onClick={plantMenuClick}
        disabled={!showPlantSelector}
        data-testid="plant.popover-menu.toggle">
        <Typography variant="body2" className={plantNameClass}>
          &nbsp;{plantName}
        </Typography>
        {showPlantSelector && (
          <>{plantMenu ? <ArrowDropUp className={classes.headerTexts} /> : <ArrowDropDown className={classes.headerTexts} />}</>
        )}
      </FormButton>
    );
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
        [classes.collapsed]: !open,
      })}>
      <Toolbar className={classes.toolbar}>
        <>
          <FormButton
            onClick={userMenuClick}
            data-testid="user.popover-menu.toggle"
          >
            <UserIcon className={classes.headerTexts} />
            <Typography variant="body2" className={classes.headerTexts}>
              &nbsp;&nbsp;{accountInfo?.name}
            </Typography>
            {userMenu ? <ArrowDropUp className={classes.headerTexts} /> : <ArrowDropDown className={classes.headerTexts} />}
          </FormButton>
          <Popover
            open={Boolean(userMenu)}
            anchorEl={userMenu}
            onClose={userMenuClose}
            classes={{ paper: classes.userPopover }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <MenuItem
              data-testid="user.popover-menu.item.profile"
              onClick={() => {
                userMenuClose();
                history.push(ROUTES_PATH.USERS_PROFILE);
              }}>
              <ListItemIcon>
                <UserIcon htmlColor="#fff" />
              </ListItemIcon>
              <ListItemText primary={t('user.profile.title')} />
            </MenuItem>
            <MenuItem
              id="menu-item-anchor"
            >
              <FormButton
                data-testid="user.popover-menu.item.language-menu"
                onClick={languageMenuOpen}
              >
                <ListItemIcon>
                  <LanguageIcon htmlColor="#fff" />
                </ListItemIcon>
                <ListItemText primary={t('language')} />
                <ArrowRightIcon htmlColor="#fff" />
              </FormButton>
            </MenuItem>
            <MenuItem
              data-testid="user.popover-menu.item.logout"
              onClick={() => {
                logout();
                userMenuClose();
              }}
            >
              <ListItemIcon>
                <Icon color="secondary">exit_to_app_outlined</Icon>
              </ListItemIcon>

              <ListItemText primary="Logout" />
            </MenuItem>
          </Popover>
          <Popover
            open={Boolean(languageMenu)}
            onClose={languageMenuClose}
            classes={{ paper: classes.languagePopover }}
            anchorReference="anchorPosition"
            anchorPosition={getLanguagePopoverPosition()}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <MenuItem
              data-testid="user.popover-menu.item.language-menu.subitem.english-option"
              onClick={() => updateUserLanguage('en')}
            >
              <ListItemText primary="en-US" />
              &nbsp;&nbsp;
              {language === 'en' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </MenuItem>
            <MenuItem
              onClick={() => updateUserLanguage('pt-BR')}
              data-testid="user.popover-menu.item.language-menu.subitem.portuguese-option"
            >
              <ListItemText primary="pt-BR" />
              &nbsp;&nbsp;
              {language === 'pt-BR' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </MenuItem>
            <MenuItem
              onClick={() => updateUserLanguage('es')}
              data-testid="user.popover-menu.item.language-menu.subitem.spanish-option"
            >
              <ListItemText primary="es" />
              &nbsp;&nbsp;
              {language === 'es' ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </MenuItem>
          </Popover>
          <Popover
            open={Boolean(plantMenu)}
            anchorEl={plantMenu}
            onClose={plantMenuClose}
            classes={{ paper: classes.plantPopover }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Box className={classes.plantPopoverContainer}>
              <Grid className={classes.searchContainer}>
                <TextField
                  data-testid="plant.popover-menu.search-input"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={t('filter.search.plant')}
                  autoComplete="off"
                  style={{ fontWeight: 'bolder', width: '150w' }}
                  fullWidth
                  InputProps={{ endAdornment: <SearchIcon fontSize="small" color="disabled" /> }}
                />
              </Grid>
              <Grid className={classes.plantsContainer}>
                {filteredPlants?.length === 0 && search !== '' ? (
                  <Typography variant="body2" align="center" color="secondary" style={{ margin: '8px 0' }}>
                    {t('filter.search.plant.not-found')}
                  </Typography>
                ) : (
                  filteredPlants?.map((p, index) => {
                    return (
                      <Box sx={{ width: '100%' }} key={p?.id}>
                        <FormButton
                          className={classes.plantsItem}
                          onClick={() => onClickToChangeUserPlant(p.id)}
                          data-testid={`plant.popover-menu.item.plant-${p?.id}`}
                        >
                          <Typography variant="body2" color="secondary" style={{ maxWidth: 200 }}>
                            <EllipsedText lines={1} text={p?.name} />
                          </Typography>
                          <Typography variant="caption" color="secondary" style={{ maxWidth: 200 }}>
                            <EllipsedText lines={1} text={p?.customerName} />
                          </Typography>
                          <Typography variant="caption" color="secondary" style={{ maxWidth: 200 }}>
                            <EllipsedText lines={1} text={t(getStatusTranslationKey(p?.plantStatus))} />
                          </Typography>
                        </FormButton>
                        {index !== filteredPlants?.length - 1 && <Divider className={classes.divider} />}
                      </Box>
                    );
                  })
                )}
              </Grid>
              <Box marginTop={2} hidden={userInfo?.userProfileName !== UserRoles.ADMINISTRATOR}>
                <FormButton
                  data-testid="plant.popover-menu.button.plant-management"
                  onClick={() => {
                    plantMenuClose();
                    history.push(ROUTES_PATH.USERS_PLANT_MANAGEMENT);
                  }}
                  variant="secondary"
                  fullWidth
                  className={classes.plantPopoverButton}>
                  {t('admin-panel.open')}
                </FormButton>
              </Box>
            </Box>
          </Popover>
          <Box className={classes.headerSeparator} />
          {renderSelectedPlant()}
        </>
      </Toolbar>
      <Box className={classes.headerBorderBottom} />
    </AppBar>
  );
};

export default Header;
