/* eslint-disable jsx-a11y/media-has-caption */
import { ReactElement } from 'react';
import ladleSlagModelpng from '@app/assets/images/home/ladle_slag_model.png';
import ReactPlayer from 'react-player/file';

const SectionCareerHero = (): ReactElement => {
  return (
    <section
      id="section-career-hero"
      className="search-hero bg-section container-top grid-x justify-center align-center white section-active section-past">
      <div className="grid-container">
        <div className="grid-x justify-center align-middle">
          <div className="large-12 cell white center">
            <h2 className="hero-hl h1">
              <a href="https://www.youtube.com/watch?v=s_ETk9Xihg0" target="_blank" rel="noopener noreferrer">
                <img alt="Icon of Ladle Slag Model" src={ladleSlagModelpng} width="auto" />
              </a>
            </h2>
            <div className="text-xl slider-intro">
              <p>
                <span className="lsm-dark">
                  <b>STOP</b>
                </span>{' '}
                guessing. <br />
                <b>START</b> optimizing!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* https://stackoverflow.com/questions/76425979/reactplayer-doesnt-load-videos-initially/76426084#76426084 */}
      <div className="section-bg-image darken">
        <ReactPlayer
          url={`${process.env.PUBLIC_URL}/ladle_slag_model_lsm_en.mp4`}
          playing={true}
          loop={true}
          volume={0}
          muted={true}
          playsinline={true}
          pip={true}
          width='100%'
          height='100%'
        />
      </div>
    </section>
  );
};

export default SectionCareerHero;
