import { SaturationMode } from '@app/api/heats/HeatsResponse';

const listObjectStructureInitialState = { name: '', weight: '', externalId: '' };

// TO DO - CREATE TYPE FILES TO STEP FIELDS AND REFACTOR THE CODE DUE TO TYPE DEFINITION
const step1 = {
  ladleFurnace: 'none',
  ladleFurnaceId: 0,
  heatName: '',
  heatDescription: '',
  steelGrade: '',
  steelGradeCategory: '',
  finalTemperature: '',
  carryoverSlagWeight: '',
  skimmerActive: false,
  skimmerPostSlagWeight: '',
  tap: '',
  arcLength: '',
  minimumSlagDepth: '',
  minimumSlagWeight: '',
  ladleName: '',
  ladleLifeTime: '',
  ladleId: '',
  isNewCampaign: false,
  isLadleLifeInvalid: false,
  isHeatNameInvalid: false,
  saturationMode: '' as SaturationMode | '',
  finalOxygenInSteel: null as number | null,
  initialSulfurPercentage: null as number | null,
};

const step2 = {
  alloysChargeWeightList: Array(listObjectStructureInitialState),
  fluxesChargeWeightList: Array(listObjectStructureInitialState),
  fluxesChargeWeightFixedList: Array(listObjectStructureInitialState),
  deoxidizersChargeWeightList: Array(listObjectStructureInitialState),
};

const step3 = {
  alloysChargeWeightListAtLF: '',
  fluxesChargeWeightListAtLF: '',
  deoxidizersChargeWeightListAtLF: '',
  siRecovery: '',
  alRecovery: '',
  mnRecovery: '',
};

const step4 = {};
const step5 = {};
const step6 = {};
const step7 = {};

export const stepsInitialState = { step1, step2, step3, step4, step5, step6, step7 };

export const initialState = { ...step1, ...step2, ...step3, ...step4, ...step5, ...step6, ...step7 };

export type InitialState = typeof initialState;

export const getStep = (step: keyof typeof stepsInitialState, calculationData: InitialState) => {
  const stepKeys = Object.keys(stepsInitialState[step]);
  const stepCalculation = {};
  stepKeys.forEach(sk => {
    //@ts-ignore
    stepCalculation[sk] = calculationData[sk];
  });
  return stepCalculation;
};
