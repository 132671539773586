import { ReactElement } from 'react';
import rhim_logo from '@app/assets/images/home/rhi_logo.svg';
import '../css/foundation.css';
import '../css/front.css';
import '../css/refresh.css';

import {
  APP_HOME_WIKI_ENABLED
} from '../../../utils/constants';

const Header = (): ReactElement => {
  return (
    <header>
      <div className="grid-container fluid" id="mainNavigation" style={{ top: '0px' }}>
        <div className="grid-x grid-margin-x align-middle nav-container">
          <div className="cell logo auto">
            <a className="logo-link block" href="https://www.rhimagnesita.com/" target="_blank" rel="noreferrer">
              <img alt="RHI Magnesita logo" src={rhim_logo} />
            </a>
          </div>
          <div className="cell grid-x auto align-center main-mavs align-middle">
            <div className="cell grid-x auto align-right main-menu-cell">
              <div className="main-menu-box">
                <div className="menu-main-menu-refresh-container">
                  <ul id="menu-main-menu-refresh" className="menu vertical medium-horizontal hide-for-small-only">
                    <li id="menu-item-17940"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17940">
                      <a href="#why-lsm">Why? </a>
                    </li>
                    <li id="menu-item-17956"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-17956">
                      <a href="#how-lsm">How?</a>
                    </li>
                    <li id="menu-item-17997"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17997">
                      <a href="#what-lsm">What?</a>
                    </li>
                  </ul>
                </div>
                <div className="hamburger">
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
                <div className="mobile-menu-bottom hide-for-medium">
                  <div className="menu-main-menu-refresh-container">
                    <ul id="menu-main-menu-refresh-1" className="menu vertical top-bar-menu">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17940">
                        <a href="/about/who-we-are/">Why</a>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-17956">
                        <a href="#how-lsm">How</a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17997">
                        <a href="#what-lsm">What</a>
                      </li>
                    </ul>
                  </div>
                  <div className="text-center mobile-extra menu-top-bar-menu">
                    <div className="menu-top-bar-menu-container">
                      <a href={`${process.env.PUBLIC_URL}/login`} target="_self" className="button button-secondary m-3">Login</a>
                      {APP_HOME_WIKI_ENABLED ? <a href={`${process.env.PUBLIC_URL}/login`} target="_blank"  rel="noreferrer" className="button button-tertiary m-3">Wiki</a> : <></> }
                      <a href="mailto:DigitalSolutionsSAM@rhimagnesita.com?subject=I%20want%20to%20know%20more%20about%20Ladle%20Slag%20Model%20by%20RHI%20Magnesita" target="_self" className="button m-3">
                          Contact
                          Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cell auto show-for-large">
              <div className="side-nav-top grid-x align-middle">
                <div className="cell auto right">
                  <a href={`${process.env.PUBLIC_URL}/login`} target="_self" className="button button-secondary m-3">Login</a>
                  {APP_HOME_WIKI_ENABLED ? <a href={`${process.env.PUBLIC_URL}/login`} target="_blank"  rel="noreferrer" className="button button-tertiary m-3">Wiki</a> : <></> }
                  <a href="mailto:DigitalSolutionsSAM@rhimagnesita.com?subject=I%20want%20to%20know%20more%20about%20Ladle%20Slag%20Model%20by%20RHI%20Magnesita" target="_self" className="button m-3">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </header>
  );
};

export default Header;
