import { useHeatsService } from '@app/api/heats';
import { ExistsHeatRequestDTO, ExistsHeatResponseDTO } from '@app/api/heats/useHeatsService';
import { useState } from 'react';
import { HooksStatus } from './types';

export interface UseCheckIfHeatNameExists {
  status: HooksStatus;
  data: ExistsHeatResponseDTO;
}

const infoObjectInitialState: UseCheckIfHeatNameExists = {
  status: 'idle',
  data: {
    heatName: '',
    heatNameCanBeUsed: true,
    ladleDataCanBeManuallyChanged: true,
    ladleName: 0,
    ladleLife: 0,
    campaignNumber: 0,
  },
};

const useCheckIfHeatNameExists = (): [
  UseCheckIfHeatNameExists,
  (requestData: ExistsHeatRequestDTO) => Promise<void>,
] => {
  const { verifyIfHeatNameExists } = useHeatsService();

  const [infoObject, setInfoObject] = useState<UseCheckIfHeatNameExists>(infoObjectInitialState);

  const checkIfHeatNameExists = async (requestData: ExistsHeatRequestDTO) => {
    try {
      setInfoObject(prevState => ({
        ...prevState,
        status: 'loading',
      }));

      const response = await verifyIfHeatNameExists(requestData);

      setInfoObject({
        data: response,
        status: 'succeeded',
      });
    } catch (err) {
      setInfoObject({
        data: {
          heatName: '',
          heatNameCanBeUsed: false,
          ladleDataCanBeManuallyChanged: true,
          ladleLife: 0,
          ladleName: 0,
          campaignNumber: 0,
        },
        status: 'error',
      });
    }
  };

  return [infoObject, checkIfHeatNameExists];
};

export default useCheckIfHeatNameExists;
