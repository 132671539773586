export const ROUTES_PATH = {
  replaceRoute: (path: string, search: string, replace: string) => path.replace(search, replace),

  // landing page
  LANDING_PAGE: '/',

  // heats lists (draft / operation)
  APPLICATIONS_HEATS: '/app/heats',
  APPLICATIONS_DRAFTS: '/app/heats/drafts',

  // create heats routes (ladle furnace / tapping x draft / operation)
  APPLICATIONS_HEATS_LADLE_FURNACE_CREATE: '/app/heat-calculation/ladle-furnace',
  APPLICATIONS_HEATS_TAPPING_CREATE: '/app/heat-calculation/tapping',

  // view operation heats (ladle furnace / tapping)
  APPLICATIONS_HEATS_LADLE_FURNACE_VIEW: '/app/heat-calculation/ladle-furnace/view/:id',
  APPLICATIONS_HEATS_TAPPING_VIEW: '/app/heat-calculation/tapping/view/:id',

  // view draft heats (ladle furnace / tapping)
  APPLICATIONS_DRAFT_LADLE_FURNACE_VIEW: '/app/heat-calculation/ladle-furnace/draft/view/:id',
  APPLICATIONS_DRAFT_TAPPING_VIEW: '/app/heat-calculation/tapping/draft/view/:id',

  // edit draft heats (ladle furnace / tapping)
  APPLICATIONS_DRAFT_LADLE_FURNACE_EDIT: '/app/heat-calculation/ladle-furnace/draft/edit/:id',
  APPLICATIONS_DRAFT_TAPPING_EDIT: '/app/heat-calculation/tapping/draft/edit/:id',

  APPLICATIONS_STEEL_GRADE: '/app/settings/steel-grade',

  SETTINGS_CARRYOVER_SLAGS: '/app/settings/carryover-slags',

  USERS_PROFILE: '/app/users/profile',
  USERS_USER_MANAGEMENT: '/app/management/users',
  USERS_PLANT_MANAGEMENT: '/app/management/plants',
  USERS_PLANT_MANAGEMENT_DETAILS: '/app/management/plants/details/:id',

  PLANT_OVERVIEW: '/app/overview',
  PLANT_EQUIPMENT: '/app/equipment',
  PLANT_ADDITIONS: '/app/additions',
};
