import { ReactElement } from 'react';

import massBalanceLadleSlagModelLsm from '@app/assets/images/home/mass_balance_ladle_slag_model_lsm.png';
import plus from '@app/assets/images/home/plus.png';
import equals from '@app/assets/images/home/equals.png';
import liquidusLineLadleSlagModelLsm from '@app/assets/images/home/liquidus_line_ladle_slag_model_lsm.png';
import recommendationFluxesLadleSlagModelLsm from '@app/assets/images/home/recommendation_fluxes_ladle_slag_model_lsm.png';

const SectionFlex3What
 = (): ReactElement => {
  return (
    <section id="section-flex3 what"
    className="sustainability-section sustainability-flex3 section-dark section-change-dark secbg-rbr section-past">
      <div className="grid-container" id="what-lsm">
        <div className="grid-x justify-center">
            <div className="large-8 cell section-head mg-bottom-2 center">
                <h2><span className="lsm-dark">What</span> is the Ladle Slag Model (LSM)?</h2>
                <p>
                    The concept behind the LSM is to use the<b> mass balance </b>approach and
                    mathematical relationships derived from published phase diagrams for <b>slag optimization</b>.
                </p>
            </div>
        </div>
      </div>
    <div className="sec-module modulenum-1 bg-wrapper full mod-icon-box-grid">
      <div className="grid-container">
          <div className="grid-x grid-margin-x grid-margin-y justify-center">
              <div className="large-3 medium-6 cell center icon-box-grid-cell">
                  <figure className="icon-box-grid-icon">
                      <img alt="A schematic view of a mass balance to calculate slag chemistry" src={massBalanceLadleSlagModelLsm} />
                  </figure>
                  <div className="content">
                      <h4>Mass Balance</h4>
                  </div>
              </div>

              <div className="large-1 medium-3 cell center mt-5">
                  <figure className="icon-box-grid-icon">
                      <img alt="An icon of a plus sign" src={plus} />
                  </figure>
              </div>

              <div className="large-3 medium-6 cell center icon-box-grid-cell">
                  <figure className="icon-box-grid-icon">
                      <img alt="A ternary phase diagram to represent a liquidus line for slags" src={liquidusLineLadleSlagModelLsm} />
                  </figure>
                  <div className="content">
                      <h4>Liquidus Line</h4>
                  </div>
              </div>

              <div className="large-1 medium-3 cell center ">
                  <figure className="icon-box-grid-icon">
                      <img alt="An icon of equals sign" src={equals} />
                  </figure>
              </div>

              <div className="large-3 medium-6 cell center icon-box-grid-cell">
                  <figure className="icon-box-grid-icon">
                      <img alt="An icon representing the recommendations made by Ladle Slag Model" src={recommendationFluxesLadleSlagModelLsm} />
                  </figure>
                  <div className="content">
                      <h4>Recommendation of fluxes and deoxidants</h4>
                  </div>
              </div>
          </div>
      </div>
    </div>
    </section>
  );
};

export default SectionFlex3What
;
