import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

import './js/jquery.js';
import './js/jquery-migrate.js';
import './js/nav-scroll.js';
import './js/itro-scripts.js';
import './js/wcd.embed.js';
import './js/inline-1.js';
import './js/inline-3.js';


const Home = (): ReactElement => {
  return (
    <div className="" style={{ paddingTop: '0px'}}>
      <Helmet>
        <link rel="stylesheet" id="custom-css" href="./css/custom.css" type="text/css" media="all" />
        <link rel="stylesheet" id="wpdm-font-awesome-css" href="./css/all.min.css" type="text/css" media="all" />
        <link rel="stylesheet" id="wpdm-frontend-css-css"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" type="text/css" media="all" />
        <link rel="stylesheet" id="roboto-css" href="./css/roboto.css" type="text/css" media="all" />
        <link rel="stylesheet" id="wbfnts-css" href="./css/webfonts.css" type="text/css" media="all" />
        <link rel="stylesheet" id="wp-featherlight-css" href="./css/wp-featherlight.min.css" type="text/css" media="all" />
        <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          id="wpdm-poper-js">
        </script>
        <script type="text/javascript" src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          id="wpdm-frontend-js-js">
        </script>
      </Helmet>
      <Header />
      <Main />
      <Footer />
      <Helmet>
        <script type="text/javascript" src="./js/inline-4.js" />
        <script type="text/javascript" src="./js/inline-5.js" />
      </Helmet>
      <div id="fb-root"></div>
      <Helmet>
        <link rel="stylesheet" id="rs-plugin-settings-css" href="./css/rs6.css" type="text/css" media="all" />
        <script type="text/javascript" src="./js/hooks.min.js" id="wp-hooks-js"></script>
        <script type="text/javascript" src="./js/i18n.min.js" id="wp-i18n-js"></script>
        <script type="text/javascript" src="./js/inline-6.js" />
        <script type="text/javascript" src="./js/inline-7.js" />
        <script type="text/javascript" src="./js/index.js" id="swv-js"></script>
        <script type="text/javascript" src="./js/script-update-refresh.js" id="script-refresh-js"></script>
        <script type="text/javascript" src="./js/jquery.mask.js" />
      </Helmet>
    </div>
  );
};

export default Home;
