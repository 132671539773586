import { ReactElement } from 'react';
import SectionCareerHero from './SectionCareerHero';
import SectionFlex1 from './SectionFlex1';
import SectionFlex2 from './SectionFlex2';
import SectionFlex3What from './SectionFlex3What';
import SectionFlex3 from './SectionFlex3';

const Main = (): ReactElement => {
  return (
    <main>
      <SectionCareerHero />
      <SectionFlex1 />
      <SectionFlex2 />
      <SectionFlex3What />
      <SectionFlex3 />
    </main>
  );
};

export default Main;
