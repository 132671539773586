import { ReactElement } from 'react';

import dynamicLadleDiameterLadleSlagModelLsm from '@app/assets/images/home/dynamic-ladle_diameter-ladle-slag-model-lsm.png'
import processParametersConstraintsLadleSlagModelLsm from '@app/assets/images/home/process-parameters-constraints-ladle-slag-model-lsm.png'
import furnaceSetupsLadleSlagModelLsm from '@app/assets/images/home/furnace-setups-ladle-slag-model-lsm.png'
import additionsLadleSlagModelLsm from '@app/assets/images/home/additions-ladle-slag-model-lsm.png'
import carryoverSlagsFromEafToBofLadleSlagModelLsm from '@app/assets/images/home/carryover-slags-from-eaf-to-bof-ladle-slag-model-lsm.png'
import steelGradeLadleSlagModelLsm from '@app/assets/images/home/steel-grade-ladle-slag-model-lsm.png'

const SectionFlex2 = (): ReactElement => {
  return (
    <section id="section-flex2 how" className="sustainability-section sustainability-flex2 section-past">
      <div className="grid-container" id="how-lsm">
          <div className="grid-x justify-center">
              <div className="large-8 cell section-head mg-bottom-2 center">
                  <h2><span className="lsm-dark">How</span> do we do it?</h2>
                  <p>Recommendations are dynamically adjusted to your process <br/> by a complete mass balance of slag formation in the ladle</p>
                  <div className="content mg-top-1">
                      <p></p>
                  </div>
              </div>
          </div>
      </div>
      <div className="sec-module modulenum-2 full bg-wrapper mod-icon-custom-grid">
          <div className="grid-container">
              <div className="grid-x grid-margin-x grid-margin-y justify-center">
                  <div className="cell small-12 medium-4 large-3 center icon-custom-grid-cell">
                      <figure className="icon-box-grid-icon">
                          <img alt="An icon representing a calculation of a dynamic ladle diameter" src={dynamicLadleDiameterLadleSlagModelLsm} />
                      </figure>
                      <div className="content">
                          <h4>Ladle information <br/>&nbsp;</h4>
                          <p className="">Ladle diameter, average ladle lifetime, initial and final lining thickness</p>
                      </div>
                  </div>
                  <div className="cell small-12 medium-4 large-3 center icon-custom-grid-cell">
                      <figure className="icon-box-grid-icon">
                          <img alt="An icon representing process parameters and constraints" src={processParametersConstraintsLadleSlagModelLsm} />
                      </figure>
                      <div className="content">
                          <h4>Process parameters and constraints</h4>
                          <p>Ladle capacity, maximum slag weight, minimum slag weight after skimmer and arc coverage</p>
                      </div>
                  </div>
                  <div className="cell small-12 medium-4 large-3 center icon-custom-grid-cell">
                      <figure className="icon-box-grid-icon">
                          <img alt="An icon representing the ladle furnace setup" src={furnaceSetupsLadleSlagModelLsm} />
                      </figure>
                      <div className="content">
                          <h4>Ladle furnace setups<br/>&nbsp;</h4>
                          <p>Arc length and minimum slag depth</p>
                      </div>
                  </div>
                  <div className="cell small-12 medium-4 large-3 center icon-custom-grid-cell">
                      <figure className="icon-box-grid-icon">
                          <img alt="An icon representing the additions" src={additionsLadleSlagModelLsm} />
                      </figure>
                      <div className="content">
                          <h4>Additions<br/>&nbsp;</h4>
                          <p>Chemistry of fluxes, alloys and slag deoxidizers</p>
                      </div>
                  </div>
                  <div className="cell small-12 medium-4 large-3 center icon-custom-grid-cell">
                      <figure className="icon-box-grid-icon">
                          <img alt="An icon representing carryover slags" src={carryoverSlagsFromEafToBofLadleSlagModelLsm} />
                      </figure>
                      <div className="content">
                          <h4>Carryover slags chemistry and weight</h4>
                          <p>Different ways of slag chemistry and weight inference including steel grade based inference</p>
                      </div>
                  </div>
                  <div className="cell small-12 medium-4 large-3 center icon-custom-grid-cell">
                      <figure className="icon-box-grid-icon">
                          <img alt="An icon representing steel grade" src={steelGradeLadleSlagModelLsm} />
                      </figure>
                      <div className="content">
                          <h4>Steel grade details<br/>&nbsp;</h4>
                          <p>Your steel grade recipe matters - additions at tapping, alloy recovery, predefined additions for special purposes and tapping temperature</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
  );
};

export default SectionFlex2;
