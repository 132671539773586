import { ReactElement } from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
  customListContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left"
  },
  customList: {
    listStyle: "none",
    padding: 0,
    li: {
      background: "url('https://cdn-icons-png.flaticon.com/16/1828/1828640.png') no-repeat left center",
      backgroundSize: "16px 16px",
      paddingLeft: "30px",
      fontSize: "18px",
      margin: "3px 0",
      display: "block",
      textAlign: "left"
    }
  },
  })
);

const SectionFlex1 = (): ReactElement => {
  const classes = useStyles();
  return (
    <section id="why section-flex1"
    className="sustainability-section sustainability-flex1 section-dark section-change-dark secbg-rbr section-past">
      <div className="grid-container" id="why-lsm">
        <div className="grid-x justify-center">
            <div className="large-8 cell section-head mg-bottom-2 center">
                <div className="content mg-top-1">
                    <h2><span className="lsm-dark">Why</span> should I use Ladle Slag Model?</h2>
                    <div className={classes.customListContainer}>
                        <ul className="check-list">
                            <li>Helps you achieve dual saturated slags (CaO and MgO-rich slags)</li>
                            <li>CaO-saturated slags can lead to effective desulfurization</li>
                            <li>MgO-saturated slags minimize chemical corrosion and improve ladle lifetime</li>
                            <li>Recommends accurate quantity of fluxes and slag deoxidizers</li>
                            <li>Helps to maintain adequate amount of slag for side wall protection from arc flare</li>
                            <li>Enhances thermal efficiency through arc coverage and may lead to energy saving</li>
                            <li>Reduces refractory wear <b>up to 21%</b></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div className="mt-5 grid-container text-center">
        <div className="grid-x grid-margin-x align-stretch">
            <div className="large-4 cell main-fact">
                <div className="fact-box">
                    <h3>Reduction of Wear</h3>
                    <div className="stock-iframe">
                        <div id="stock-info" className="negative">
                        <div className="info">
                          Reduction of wear rate in slag line leading to lifespan increase up to 21%
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="large-4 cell main-fact">
                <div className="fact-box">
                    <h3>Potential Savings</h3>
                    <div className="stock-iframe">
                        <div id="stock-info" className="negative">
                            <div className="info">Better usage of fluxes and slag deoxidizers</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="large-4 cell main-fact">
                <div className="fact-box">
                    <h3>High-Quality Steel</h3>
                    <div className="stock-iframe">
                        <div id="stock-info" className="negative">
                            <div className="info">Lower levels of inclusions and impurities in steel</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFlex1;
