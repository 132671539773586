import { ReactElement } from 'react';


const SectionFlex3 = (): ReactElement => {
  const PaperABMV7 =  `${process.env.PUBLIC_URL}/articles/2023-FALSETTI-et-al-Effects-alternative-slag-fluidizers-secondary-steelmaking.pdf`;
  const fullTextGOB_V04 =  `${process.env.PUBLIC_URL}/articles/2016-LOPEZ-et-al-Slag-Modeling-Ladle-Life-Integrated-Steel-Mill.pdf`;
  const fullTextV1_2 =  `${process.env.PUBLIC_URL}/articles/2023-LOPEZ-et-al-Modeling-Ladle-Slags-Integration-Supervisory-System.pdf`;

  return (
    <section id="section-flex3" className="sustainability-section sustainability-flex3 section-past">
      <div className="grid-container">
        <div className="grid-x justify-center">
          <div className="large-8 cell section-head mg-bottom-2 center">
            <h2>Scientific Articles</h2>
            <p><b>...</b></p>
          </div>
        </div>
      </div>

      <div className="sec-module modulenum-1 bg-wrapper full mod-icon-box-grid">
        <div className="grid-container">
          <div className="grid-x grid-margin-x grid-margin-y justify-center">
            <div className="large-3 medium-6 cell center icon-box-grid-cell">
              <div className="content">
                <h4>Assessing the effects of using alternative slag fluidizers in secondary steelmaking</h4>
              </div>
              <div className="link-wrap mg-top-1">
                <a className="more-link" href={PaperABMV7} target="_blank" rel="noreferrer">read more</a>
              </div>
            </div>
            <div className="large-3 medium-6 cell center icon-box-grid-cell">
              <div className="content">
                <h4>
                  Modeling ladle slags using a mass balance approach and integration into the ladle
                  furnace process supervisory system
                </h4>
              </div>
              <div className="link-wrap mg-top-1">
                <a className="more-link" href={fullTextV1_2} target="_blank" rel="noreferrer">
                  read
                  more
                </a>
              </div>
            </div>
            <div className="large-3 medium-6 cell center icon-box-grid-cell">
              <div className="content">
                <h4>Slag Modeling for Increasing Ladle Life at Integrated Steel Mill - Brazil</h4>
              </div>
              <div className="link-wrap mg-top-1">
                <a className="more-link" href={fullTextGOB_V04} target="_blank" rel="noreferrer">read more</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default SectionFlex3;
