import $ from 'jquery';

function getScrollElements(elm) {
  let elements = [];
  let setid = (elm == '.col-gallery-wrap');
  let idcounter = 0;
  $(elm).each(function () {
    idcounter++;
    let newid = elm + '--' + idcounter;
    if (setid) { $(this).addClass(newid.replace('.', '')); }
    let id = (setid) ? newid : $(this).attr('id');
    let bgchange = $(this).hasClass('section-change-dark');
    let pos = $(this).position();
    let top = pos.top;
    let height = $(this).outerHeight();
    elements.push({
      id: id,
      bgchange: bgchange,
      top: top,
      height: height,
      end: top + height,
      state: [], // save state of animations to sections
    });
  });
  return elements;
}

/* animate numbers */
function animatemynumbers(obj, initVal, lastVal, duration) {
  let startTime = null;
  let currentTime = Date.now();
  //pass the current timestamp to the step function
  const step = (currentTime) => {
    // if the start time is null, assign the current time to startTime
    startTime = (startTime === null) ? currentTime : startTime;
    //calculate the value to be used in calculating the number to be displayed
    const progress = Math.min((currentTime - startTime) / duration, 1);
    //calculate what to be displayed using the value gotten above
    let nownumber = Math.floor(progress * (lastVal - initVal) + initVal);
    obj.innerHTML = nownumber.toLocaleString('de-DE');
    //checking to make sure the counter does not exceed the last value (lastVal)
    if (progress < 1) { window.requestAnimationFrame(step); }
    else { window.cancelAnimationFrame(window.requestAnimationFrame(step)); }
  };
  //start animating
  window.requestAnimationFrame(step);
}

function initNavScroll() {
  var lastscroll = 0;
  var elm = {
    'nav': $('#mainNavigation'),
  };
  var menuheight = elm.nav.height();
  var sections = getScrollElements('section');
  var winh = $(window).height();

  $(window).resize(function () {
    // eslint-disable-next-line no-undef
    sections = document.getElementsByTagName('section');
    winh = $(window).height();

  });

  let bodypadding = ($('body').hasClass('overlay-nav')) ? 0 : menuheight;

  $(window).scroll(function () {
    let pos = $(document).scrollTop();

    // Nav
    if (pos > menuheight + 100) {
      $('body').addClass('nav-floating');
      $('body').css('padding-top', bodypadding);
    }
    else {
      $('body').removeClass('nav-floating');
      $('body').css('padding-top', 0);
    }
    if (pos > lastscroll + 70) {
      $('body').addClass('nav-slide-up');
      elm.nav.css('top', (menuheight + 5) * -1);
      lastscroll = pos;
    }
    if (pos < lastscroll - 70) {
      $('body').removeClass('nav-slide-up');
      elm.nav.css('top', 0);
      lastscroll = pos;
    }

    // sections
    for (var i = 0; i < sections.length; i++) {
      let checkpast = sections[i].end <= pos;
      let checkactive = sections[i].top <= (pos + winh) && !checkpast;
      let checkcenter = sections[i].top <= (pos + (winh / 2)) && sections[i].end >= (pos + (winh / 2));
      let elmobj = $('#' + sections[i].id);
      if (checkactive) {
        elmobj.addClass('section-active');
      }
      else if (checkpast) {
        elmobj.addClass('section-past');
      }
      else {
        elmobj.removeClass('section-active');
      }
      if (checkcenter) {
        if (sections[i].bgchange) {
          $('body').addClass('body-dark-section');
        }
        else {
          $('body').removeClass('body-dark-section');
        }

        // animate numbers in facts
        if (sections[i].id == 'section-facts' && sections[i].state.indexOf('numbers') < 0) {
          sections[i].state.push('numbers');
          let num1 = document.getElementById('fact-hl-1');
          let num2 = document.getElementById('fact-hl-2');
          let num3 = document.getElementById('fact-hl-3');
          let num4 = document.getElementById('fact-hl-4');
          let num5 = document.getElementById('fact-hl-5');
          if (typeof num1 == 'object') {
            let n1to = num1.getAttribute('data-numimate');
            animatemynumbers(num1, 0, n1to, 1000);
          }
          if (typeof num2 === 'object') {
            let n2to = num2.getAttribute('data-numimate');
            setTimeout(function () { animatemynumbers(num2, 0, n2to, 1000); }, 400);
          }
          if (typeof num3 === 'object') {
            let n3to = num3.getAttribute('data-numimate');
            setTimeout(function () { animatemynumbers(num3, 0, n3to, 1000); }, 800);
          }
          if (typeof num4 === 'object') {
            let n4to = num4.getAttribute('data-numimate');
            setTimeout(function () { animatemynumbers(num4, 0, n4to, 1000); }, 1200);
          }
          if (typeof num5 === 'object') {
            let n5to = num5.getAttribute('data-numimate');
            setTimeout(function () { animatemynumbers(num5, 0, n5to, 1000); }, 1600);
          }
        }
      } // end checkcenter

    } // end sections



  })
}



window.addEventListener("load", function () {
  initNavScroll();
});

// Wait for all images, stylesheets, scripts, and other external resources to load
function waitForAllResources() {
  return new Promise((resolve) => {
    // Check if document is already fully loaded
    if (document.readyState === "complete") {
      resolve(); // If yes, resolve immediately
    } else {
      // Wait for window.onload to ensure all resources are loaded
      window.addEventListener("load", resolve);
    }
  });
}

// Usage
async function afterAllResourcesLoaded() {
  await waitForAllResources(); // Wait until all resources are fully loaded
}

afterAllResourcesLoaded();
