import logo from '@app/assets/images/logo-big.png';
import { Box, createStyles, CssBaseline, makeStyles, Typography, Theme } from '@material-ui/core';
import AppVersion from '@app/components/AppVersion';
import { Link } from 'react-router-dom';
import FormButton from '@app/components/shared/FormButton';
import { ROUTES_PATH } from '@app/utils';

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />

      <Box display="flex" flexDirection="row" height="100vh">
        <Box display="flex" flex={1}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="logo" />

            <Typography variant="h4" style={{ color: '#fff' }}>
              Ladle Slag Model
            </Typography>
            <AppVersion />
          </div>
        </Box>

        <Box display="flex" width={400} className={classes.formColumn}>
          <div style={{ width: '100%', padding: 16 }}>
             <Link
              to={{ pathname: ROUTES_PATH.LANDING_PAGE }}
              style={{ textDecoration: 'none' }}
            >
            <FormButton
              type="submit"
              style={{ marginTop: '30px' }}
              fullWidth={true}
              variant="primary"
            >
              Go back to Login Page
            </FormButton>
            </Link>
          </div>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      padding: '8.8rem',
      width: '100%',
      background: theme.palette.primary.main
    },
    logo: {
      width: '8.8rem',
      marginBottom: '2.2rem',
    },
    formColumn: {
      background: '#fff',
    },
  }),
);

export default PageNotFound;
